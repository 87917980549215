<template>
  <b-row>
    <b-col cols="12" v-if="hasPermission('show_branches')">
    
      <b-card>
      <validation-observer ref="simpleForm">
      <form >
      <b-tabs lazy vertical nav-wrapper-class="nav-hidden" align="left" >
        <b-tab
    
      >
            <template #title>
              <feather-icon icon="CodepenIcon" />
              <span>Branch Details</span>
            </template>


            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('name')" label-for="vi-name">
                
                    <b-form-input
                    :disabled="true"
                      id="vi-name"
                      type="name"
                      v-model="form.name"
                      placeholder="john.doe@email.com"
                    />
                 
                
                </b-form-group>
              </b-col>

          
              <b-col md="6">
                <b-form-group label="Managerial Email" label-for="vi-email">
                  <validation-provider
                    #default="{ errors }"
                    rules="email|required"
                    name="email"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="vi-email"
                      type="email"
                      v-model="form.managrial"
                      placeholder="john.doe@email.com"
                    />
                    <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Financial Email" label-for="vi-email">
                  <validation-provider
                    #default="{ errors }"
                    rules="email|required"
                    name="email"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="vi-email"
                      type="email"
                      v-model="form.financial"
                      placeholder="john.doe@email.com"
                    />
                    <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Medical Email" label-for="vi-email">
                  <validation-provider
                    #default="{ errors }"
                    rules="email|required"
                    name="email"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="vi-email"
                      type="email"
                      v-model="form.medical"
                      placeholder="john.doe@email.com"
                    />
                    <ValidationErrors
                      
                      :frontend-errors="errors"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

         


            </b-row>

         </b-tab>

         
      </b-tabs>
      </form>
           </validation-observer>
    </b-card>
    <b-row class="mb-5 ">
      <div class="ml-5  w-100">
      
          <b-button class="btn btn-info mr-2" @click="save">
            Save
          </b-button>
        
       

        <router-link :to="`/branches`">
          <b-button class="mr-5"> Cancel </b-button>
        </router-link>

      </div>
    </b-row>
    </b-col>
  </b-row>
</template>

<script>
import show from "@/views/cases/components/show";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Multiselect from "vue-multiselect";
import { required, email, digits, length } from "@validations";
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BOverlay,
  BCard,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTag,
  BFormTags,
  BFormTextarea,
} from "bootstrap-vue";
import { localize, ValidationObserver, ValidationProvider } from "vee-validate";
import ValidationErrors from "@/views/components/common/ValidationErrors";
import { mapActions, mapGetters } from "vuex";


export default {
  components: {
    FormWizard,
    BOverlay,
    TabContent,
    BRow,
    BTab,
  BTabs,
    BFormTag,
    BFormTags,
    BFormDatepicker,
    BCol,
    BFormGroup,
    BCard,
    BFormInput,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    localize,
    Multiselect,
    ValidationErrors,
  },
  computed: {
    ...mapGetters({
      lookups: "app/lookups",
      user: "branches/item",
   //   roless: "roles/roles",
      load: "branches/load",
    //  userRoles: "roles/userRoles",
    }),

    id() {
      return this.$route.params && this.$route.params.id
        ? this.$route.params.id
        : null;
    },
  
 
  },
 
  data() {
    return {
      branches: [],
      //  roless:[],
      user_roles: [],
      errors_data: {},
      errorsdata: {},
      academic_info: false,
      address: false,
      contact: false,
      form: {},
      query: {
        ranks: true,
        faculties: true,
        employee_types: true,
        faculty_departments: true,
      },
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    ...mapActions({
      // getLookups: 'app/GET_LOOKUPS',
      saveUser: "branches/put",
    }),
 

    validateAsync: function (index) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$refs.simpleForm.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        }, 1000);
      });
    },

    init() {
     
      if (this.id) {
        this.$store.dispatch("branches/get", this.id).then((_) => {
          if (this.user) {
         
            this.form = this.user;
         
         
      
          }
        });
      }
      let query = {};
      // this.$store.dispatch("branches/branches", { query }).then((res) => {
      //   this.branches = res;
      // });

      // this.$store.dispatch("roles/getRoles").then((res) => {
      //   //  this.roless=res;
      //   //  console.log(this.roless);
      // });

    
    },

    back() {
      this.$router.push({
        name: "branches",
      });
    },
    save() {
   
      this.$refs.simpleForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("branches/put", { id: this.id, query: this.form })
            .then((res) => {

              if (res == 'error')
              {
                  this.$swal({
                            icon: "error",
                            title: 'This Email does already exist',
                            showConfirmButton: false,
                            timer: 2000,
                          });

                          // this.$router.push({
                          //   name: "users",
                          // });
              }
              else {

                    this.$swal({
                      icon: "success",
                      title: this.$t("Global.Saved"),
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.$router.push({
                      name: "branches",
                    });
              }
  
            })
            .catch((error) => {
              this.errorsdata = this.handleBackendError(
                error.response.data.errors
              );

              console.log(this.errors_data);
            });
       }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.wizard-btn,
.wizard-icon-container {
  background-color: #054978 !important;
  border-color: #054978 !important;
}

.stepTitle.active {
  color: #054978 !important;
}
</style>

